const analytics = {
  initialize: (analyticsId) => {
    const analyticsScript = document.createElement("script");
    analyticsScript.async = true;
    analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    document.head.appendChild(analyticsScript);
    const loadAnalytics = document.createElement("script");
    loadAnalytics.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${analyticsId}');`;
    document.head.appendChild(loadAnalytics);
  },

  identify(userId) {
    if (window.gtag) {
      // update gtag config with user id
      window.gtag("set", { user_id: userId });
    }
  },
  event(name, data = {}) {
    if (window.gtag) {
      //console.log(`Event ${name} with data ${JSON.stringify(data)}`);
      window.gtag("event", name, data);
    }
  },
};

export default analytics;
