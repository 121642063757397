const ALLOWED_ROLES = {
  'all': ['accountant', 'management_admin', 'management', 'super_admin'],
  'admins': ['management_admin', 'super_admin'],
  'accountants': ['accountant', 'super_admin'],
  'management': ['management_admin', 'management', 'super_admin'],
  'accountantAdmin': ['super_admin'],
  'managementAdmin': ['management_admin', 'super_admin'],
  'none': [],
};

export const verifyRole = (roles, type) => {
  const allowed = roles.some((role) => ALLOWED_ROLES[type].includes(role));
  return allowed;
};
